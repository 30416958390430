.app {
  width: 100%;
  height: 100vh;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.plate-juice {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.logo-title {
  width: 250px;
  height: 125px;
  object-fit: contain;
}

.button {
  border: 0px solid black;
  opacity: 1;
}

.button:hover {
  opacity: 0.8;
}

.home-food {
  position: absolute;
  width: 100%;
  height: 70vh;
  object-fit: contain;
  top: 20px;
}

.home-calendar {
  position: absolute;
  background-color: transparent;
  border-width: 0px;
  width: 120px;
  height: 120px;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-gallery {
  position: absolute;
  background-color: transparent;
  border-width: 0px;
  width: 120px;
  height: 120px;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-calendar-txt {
  position: absolute;
  color: blue;
  font-size: 40px;
  font-weight: bold;
}

.home-gallery-txt {
  color: blue;
  font-size: 30px;
  font-weight: bold;
}

.home-url {
  position: absolute;
  width: 35px;
  object-fit: contain;
  bottom: 30px;
  right: 0px
}

.home-time {
  width: 150px;
  object-fit: contain;
}

.home-invite {
  width: 600px;
  height: 60px;
  object-fit: contain;
}

.home-bottom-parent {
  height: 230px;
}

.home-button-img {
  width: 280px;
  height: 90px;
  object-fit: contain;
}

.home-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: transparent;
  border-width: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 35px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center
}

.home-btn-img {
  width: 60px;
  height: 60px;
}

.margin-bottom {
  width: 300px;
  height: 120px;
  display: flex;
  background-color: white;
  align-self: center;
  flex-direction: column;
  margin-bottom: 60px;
}

@media screen and (max-width: 1180px) {}

@media screen and (max-width: 1080px) {}

@media screen and (max-width: 980px) {}

@media screen and (max-width: 880px) {}

@media screen and (max-width: 780px) {}

@media screen and (max-width: 680px) {}

@media screen and (max-width: 580px) {
  .logo-title {
    width: 200px;
    height: 100px;
  }

  .plate-juice {
    width: 110px;
    height: 110px;
  }

  .home-food {
    height: 70vh;
  }

  .home-calendar {
    width: 80px;
    height: 80px;
    top: 15px;
    right: 15px;
  }

  .home-gallery {
    width: 80px;
    height: 80px;
    top: 15px;
    left: 15px;
  }

  .home-calendar-txt {
    font-size: 26px;
  }

  .home-gallery-txt {
    font-size: 20px;
  }

  .home-url {
    width: 25px;
    bottom: 150px;
  }

  .home-time {
    width: 100px;
  }

  .home-invite {
    width: 300px;
    height: 50px;
  }

  .home-bottom-parent {
    height: 300px;
  }

  .home-button-img {
    width: 200px;
    height: 70px;
  }

  .home-btn {
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px
  }

  .home-btn-img {
    width: 25px;
    height: 25px;
  }

  .margin-bottom {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 480px) {
  .logo-title {
    width: 180px;
    height: 90px;
  }

  .plate-juice {
    width: 100px;
    height: 100px;
  }

  .home-food {
    height: 80vh;
  }

  .home-calendar {
    width: 80px;
    height: 80px;
    top: 15px;
    right: 15px;
  }

  .home-gallery {
    width: 80px;
    height: 80px;
    top: 15px;
    left: 15px;
  }

  .home-calendar-txt {
    font-size: 26px;
  }

  .home-gallery-txt {
    font-size: 20px;
  }

  .home-url {
    width: 25px;
    bottom: 150px;
  }

  .home-time {
    width: 100px;
  }

  .home-invite {
    width: 300px;
    height: 50px;
  }

  .home-bottom-parent {
    height: 300px;
  }

  .home-button-img {
    width: 200px;
    height: 70px;
  }

  .home-btn {
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px
  }

  .home-btn-img {
    width: 25px;
    height: 25px;
  }

  .margin-bottom {
    margin-bottom: 120px;
  }
}